'use client';

import React from 'react';

import { CheatRestrictedBanner } from './_components/CheatRestrictedBanner';
import { QuestboardWrapper } from './_components/QuestboardContext';

interface LayoutProps {
  children: React.ReactNode;
  params: {
    subdomain: string;
  };
}

export default function Layout({ children, params: { subdomain } }: LayoutProps) {
  return (
    <QuestboardWrapper>
      <CheatRestrictedBanner />
      {children}
    </QuestboardWrapper>
  );
}
